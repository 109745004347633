import { trpc } from 'root/src/client/v2/trpc/trpc';
import { useAmbassadorPayloadFilters, } from 'root/src/client/v2/_pages/ambassador-program/context/ambassadorTableFilter.context';
import { AMBASSADOR_CREATORS_PER_PAGE } from 'root/src/client/v2/_pages/ambassador-program/constants';
export const useAmbassadorCreatorsList = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { from, to, sortBy, order, currentPage, languages, platforms, isBanned, programs, games } = useAmbassadorPayloadFilters();
    const query = trpc.ambassador.getCreatorsList.useQuery({
        cursor: {
            currentPage,
            limit: AMBASSADOR_CREATORS_PER_PAGE,
        },
        sortBy: {
            value: sortBy,
            order,
        },
        filters: {
            from,
            to,
            languages,
            platforms,
            isBanned,
            programs,
            games,
        },
    });
    return {
        isLoading: query.isLoading,
        isError: query.isError,
        creators: ((_a = query.data) === null || _a === void 0 ? void 0 : _a.creators) || [],
        allPage: ((_b = query.data) === null || _b === void 0 ? void 0 : _b.allPage) || 0,
        currentPage: ((_c = query.data) === null || _c === void 0 ? void 0 : _c.currentPage) || 1,
        availableGames: (_e = (_d = query.data) === null || _d === void 0 ? void 0 : _d.availableFilters) === null || _e === void 0 ? void 0 : _e.games,
        availableLanguages: (_g = (_f = query.data) === null || _f === void 0 ? void 0 : _f.availableFilters) === null || _g === void 0 ? void 0 : _g.languages,
    };
};
