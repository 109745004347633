export var AmbassadorSortField;
(function (AmbassadorSortField) {
    AmbassadorSortField["Ccv"] = "ccv";
    AmbassadorSortField["CompletedDares"] = "completedDares";
    AmbassadorSortField["TikTokFollowerCount"] = "tiktokFollowerCount";
    AmbassadorSortField["YouTubeFollowerCount"] = "youtubeFollowerCount";
    AmbassadorSortField["UserDropsCount"] = "userDropsCount";
    AmbassadorSortField["DurationMinutes"] = "durationMinutes";
})(AmbassadorSortField || (AmbassadorSortField = {}));
export var AmbassadorTableActions;
(function (AmbassadorTableActions) {
    AmbassadorTableActions["Unban"] = "unban";
    AmbassadorTableActions["Ban"] = "ban";
    AmbassadorTableActions["RemoveFromProgram"] = "removeFromProgram";
    AmbassadorTableActions["AddToProgram"] = "addToProgram";
    AmbassadorTableActions["AddToDrop"] = "AddToDrop";
    AmbassadorTableActions["AssigneeDare"] = "assigneeDare";
    AmbassadorTableActions["RemoveOutsideCreators"] = "removeOutsideCreators";
})(AmbassadorTableActions || (AmbassadorTableActions = {}));
