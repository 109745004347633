var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, createContext, useState, useContext } from 'react';
import { DarePlatform } from 'root/src/shared/@types/platforms';
import { AmbassadorSortField } from 'root/src/shared/ambassador/types';
import { DashboardOrderSortTypes } from 'root/src/shared/@types/dashboard';
import { useAmbassadorCreatorsList } from 'root/src/client/v2/_pages/ambassador-program/hooks/useAmbassadorCreatorsList';
export var FilterConst;
(function (FilterConst) {
    FilterConst["All"] = "all";
})(FilterConst || (FilterConst = {}));
export const AmbassadorTableFilterContext = createContext(null);
export const AmbassadorTableFilterProvider = ({ children }) => {
    const provider = useContextProvider();
    return (<AmbassadorTableFilterContext.Provider value={provider}>
			{children}
		</AmbassadorTableFilterContext.Provider>);
};
const useContextProvider = () => {
    const [language, setLanguage] = useState(FilterConst.All);
    const [gameId, setGameId] = useState(FilterConst.All);
    const [program, setProgram] = useState(FilterConst.All);
    const [isBanned, setIsBanned] = useState(false);
    const [platform, setPlatform] = useState(DarePlatform.Any);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState(AmbassadorSortField.Ccv);
    const [order, setOrder] = useState(DashboardOrderSortTypes.DESC);
    return {
        language,
        setLanguage,
        gameId,
        setGameId,
        program,
        setProgram,
        isBanned,
        setIsBanned,
        platform,
        setPlatform,
        sortBy,
        setSortBy,
        order,
        setOrder,
        from,
        setFrom,
        to,
        setTo,
        currentPage,
        setCurrentPage,
    };
};
export const useAmbassadorFilterContext = () => {
    const context = useContext(AmbassadorTableFilterContext);
    if (!context) {
        throw new Error('useFilterContext must be used within AmbassadorTableFilterContext');
    }
    return context;
};
export const useAmbassadorTimeRange = () => {
    const { from, to, setFrom, setTo } = useAmbassadorFilterContext();
    return { from, to, setFrom, setTo };
};
export const useAmbassadorCursor = () => {
    const { currentPage, setCurrentPage } = useAmbassadorFilterContext();
    const onCurrentPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return {
        currentPage,
        onCurrentPage,
    };
};
export const useAmbassadorPayloadFilters = () => {
    const { from, to, language, gameId, program, isBanned, platform, currentPage, sortBy, order, } = useAmbassadorFilterContext();
    return {
        to,
        from,
        order,
        sortBy,
        currentPage,
        isBanned,
        languages: language === FilterConst.All ? [] : [language],
        programs: program === FilterConst.All ? [] : [program],
        games: gameId === FilterConst.All ? [] : [gameId],
        platforms: platform === DarePlatform.Any ? [] : [platform],
    };
};
export const useAmbassadorSorting = () => {
    const { sortBy, setSortBy, order, setOrder } = useAmbassadorFilterContext();
    const isSortFieldGuard = (field) => {
        return Object.values(AmbassadorSortField).some(value => value === field);
    };
    const onSorting = ({ id, isActive }) => {
        if (!isSortFieldGuard(id)) {
            return;
        }
        if (isActive) {
            setOrder((prevState) => {
                return prevState === DashboardOrderSortTypes.DESC ? DashboardOrderSortTypes.ASC : DashboardOrderSortTypes.DESC;
            });
            return;
        }
        setSortBy(id);
    };
    return {
        sortBy, order, onSorting,
    };
};
export const useAmbassadorTimeRangeFilters = () => {
    const { from, to, setFrom, setTo } = useAmbassadorFilterContext();
    return { from, to, setFrom, setTo };
};
export const usePlatformFilter = () => {
    const { platform, setPlatform } = useAmbassadorFilterContext();
    return { platform, setPlatform };
};
export const useGameFilter = () => {
    const { gameId, setGameId } = useAmbassadorFilterContext();
    return { gameId, setGameId };
};
export const useLanguageFilter = () => {
    const { language, setLanguage } = useAmbassadorFilterContext();
    return { language, setLanguage };
};
export const useProgramFilter = () => {
    const { program, setProgram } = useAmbassadorFilterContext();
    return { program, setProgram };
};
export const useIsBannedFilter = () => {
    const { isBanned, setIsBanned, setCurrentPage } = useAmbassadorFilterContext();
    const onIsBanned = (param) => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPage(1);
        setIsBanned(param);
    });
    return { isBanned, onIsBanned };
};
export const useAvailableFilter = () => {
    const { availableGames, availableLanguages } = useAmbassadorCreatorsList();
    const [games, setGames] = useState(availableGames);
    const [languages, setLanguages] = useState(availableLanguages);
    useEffect(() => {
        setGames((prevState) => {
            if (!availableGames) {
                return prevState;
            }
            return availableGames;
        });
    }, [availableGames]);
    useEffect(() => {
        setLanguages((prevState) => {
            if (!availableLanguages) {
                return prevState;
            }
            return availableLanguages;
        });
    }, [availableLanguages]);
    return {
        games: games || [],
        languages: languages || [],
    };
};
