import { useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useBrandPrograms } from 'root/src/client/v2/_pages/ambassador-program/hooks/useBrandPrograms';
export const ProgramAutocompleteInput = (props) => {
    const { selectedOption, programs, isLoading } = useProgramAutocompleteInput(props);
    return (<Autocomplete {...props} renderOption={(optionProps, option) => {
            return (<li {...optionProps}>
						{option.title}
					</li>);
        }} getOptionLabel={option => option.title} loading={isLoading} options={programs} value={selectedOption || null} isOptionEqualToValue={(option, value) => option.id === value.id} renderInput={(params) => {
            return (<TextField {...params} placeholder="Select or Type In"/>);
        }}/>);
};
const useProgramAutocompleteInput = (props) => {
    const { programs, isLoading } = useBrandPrograms();
    const selectedOption = useMemo(() => programs.find(program => program.id === props.value), [props.value, programs]);
    return {
        selectedOption,
        programs,
        isLoading,
    };
};
