import { createContext, useContext, useState, useEffect } from 'react';
import { useAmbassadorCreatorsList } from 'root/src/client/v2/_pages/ambassador-program/hooks/useAmbassadorCreatorsList';
const AmbassadorTableActionsContext = createContext(null);
export const AmbassadorTableActionsProvider = ({ children }) => {
    const provider = useProvider();
    return (<AmbassadorTableActionsContext.Provider value={provider}>
			{children}
		</AmbassadorTableActionsContext.Provider>);
};
const useProvider = () => {
    const [selectedCreators, setSelectedCreators] = useState([]);
    return {
        selectedCreators,
        setSelectedCreators,
    };
};
export const useActionsContext = () => {
    const context = useContext(AmbassadorTableActionsContext);
    if (!context) {
        throw new Error('useActionsContext must be used within AmbassadorTableActionsContext');
    }
    return context;
};
export const useSelectCreators = () => {
    const { selectedCreators, setSelectedCreators } = useActionsContext();
    const { creators, isLoading } = useAmbassadorCreatorsList();
    useEffect(() => {
        setSelectedCreators([]);
    }, [isLoading, creators.length]);
    const toggleAllCreators = () => {
        setSelectedCreators((prevCreators) => {
            if (prevCreators.length) {
                return [];
            }
            return creators;
        });
    };
    const toggleOneCreator = (selectedCreator) => {
        setSelectedCreators((prevCreators) => {
            const isSelected = prevCreators.find(creator => creator.userId === selectedCreator.userId);
            if (!isSelected) {
                return [...prevCreators, selectedCreator];
            }
            return prevCreators.filter(creator => creator.userId !== selectedCreator.userId);
        });
    };
    const isAllSelected = selectedCreators.length === creators.length;
    const isAtLeastOneSelected = selectedCreators.length > 0;
    return {
        selectedCreators,
        isAllSelected,
        isAtLeastOneSelected,
        toggleOneCreator,
        toggleAllCreators,
    };
};
