import { TRPCClientError } from '@trpc/client';
export const getErrorMessage = (err) => {
    if (err instanceof TRPCClientError) {
        try {
            const parsedError = JSON.parse(err.message);
            const error = parsedError[0];
            return error.message;
        }
        catch (_) {
            return err.message;
        }
    }
    return err.message || 'An error occurred';
};
