import { AmbassadorTableActions } from 'root/src/shared/ambassador/types';
export var TableHeaderId;
(function (TableHeaderId) {
    TableHeaderId["CheckBox"] = "checkBox";
    TableHeaderId["Languages"] = "languages";
    TableHeaderId["CreatorDatas"] = "creatorDatas";
    TableHeaderId["UserBattlePasses"] = "userBattlePasses";
    TableHeaderId["Programs"] = "programs";
    TableHeaderId["UserDrops"] = "userDrops";
})(TableHeaderId || (TableHeaderId = {}));
export const AMBASSADOR_PROGRAM_TABLE_HEADERS = [
    { label: '', id: TableHeaderId.CheckBox, width: '36px' },
    { label: 'Profiles', id: TableHeaderId.CreatorDatas, width: 12 },
    { label: 'Language / Country', id: TableHeaderId.Languages },
    { label: 'TikTok Followers', id: 'tiktokFollowerCount' },
    { label: 'YouTube Followers', id: 'youtubeFollowerCount' },
    { label: 'CCV', id: 'ccv' },
    { label: 'Stream Minutes', id: 'durationMinutes' },
    { label: 'Dares Completed', id: 'completedDares' },
    { label: 'Battle Pass', id: TableHeaderId.UserBattlePasses, width: 15 },
    { label: 'Programs', id: TableHeaderId.Programs, width: 12 },
    { label: 'Drops', id: TableHeaderId.UserDrops },
];
export const badgeColors = [
    [255, 87, 51], // Coral
    [33, 150, 243], // Bright Blue
    [233, 30, 99], // Raspberry
    [255, 152, 0], // Orange
    [63, 81, 181], // Indigo
    [41, 121, 255], // Electric Blue
    [255, 202, 40], // Soft Yellow
    [123, 31, 162], // Violet
    [239, 154, 154], // Rose Quartz
    [240, 98, 146], // Pinkish
    [144, 164, 174], // Muted Gray Blue
    [97, 97, 97], // Charcoal Gray
];
export const AMBASSADOR_CREATORS_PER_PAGE = 25;
export const AMBASSADOR_TABLE_BAN_ACTION = { type: AmbassadorTableActions.Ban, label: 'Ban' };
export const AMBASSADOR_TABLE_UNBAN_ACTION = { type: AmbassadorTableActions.Unban, label: 'Unban' };
export const AMBASSADOR_TABLE_REMOVE_OUTSIDE_CREATORS_ACTION = {
    type: AmbassadorTableActions.RemoveOutsideCreators, label: 'Remove Outside Creators',
};
export const AMBASSADOR_TABLE_BASE_ACTIONS = [
    { type: AmbassadorTableActions.RemoveFromProgram, label: 'Remove Creators from Program' },
    { type: AmbassadorTableActions.AddToProgram, label: 'Add Creators to Program' },
    { type: AmbassadorTableActions.AddToDrop, label: 'Add Creators to Drop' },
    { type: AmbassadorTableActions.AssigneeDare, label: 'Assignee Dare to Creators' },
];
