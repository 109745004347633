var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ModalLayout } from 'root/src/client/v2/common/layouts/ModalLayout';
import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormControl } from '@mui/material';
import { ProgramAutocompleteInput, } from 'root/src/client/v2/_pages/ambassador-program/components/ProgramAutocompleteInput';
import { RequiredMark } from 'root/src/client/v2/_pages/add-campaign/components/AddCampaignForm/RequiredMark';
import { ButtonNew } from 'root/src/client/v2/common/components/form/ButtonNew';
import { ErrorField } from 'root/src/client/v2/_pages/add-campaign/components/AddCampaignForm/ErrorField';
import { trpc } from 'root/src/client/v2/trpc/trpc';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'root/src/client/v2/_pages/ambassador-program/helpers/getErrorMessage';
import { useModal } from 'root/src/client/v2/common/contexts/modal.context';
import { ModalTitle } from 'root/src/client/v2/common/components/modal/ModalTitle';
import { useActionModalContext } from 'root/src/client/v2/_pages/ambassador-program/hooks/useActionModal';
const addCreatorsToProgramSchema = z.object({
    programId: z.string(),
});
export const AddCreatorsToProgramModal = () => {
    return (<ModalLayout>
			<AddCreatorsToProgramFormProvider>
				<div className="w-[400px] flex flex-col gap-4">
					<ModalTitle>Add Creators To Program</ModalTitle>
					<ProgramField />
					<HelperText />
					<Submit />
				</div>
			</AddCreatorsToProgramFormProvider>
		</ModalLayout>);
};
const Submit = () => {
    const { formState } = useFormContext();
    return (<ButtonNew disabled={formState.isSubmitting} type="submit" className="capitalize">
			Add Creators
		</ButtonNew>);
};
const ProgramField = () => {
    var _a;
    const form = useFormContext();
    const { field, fieldState } = useController({
        name: 'programId',
        control: form.control,
    });
    const onChange = (_, value) => {
        form.setValue(field.name, value === null || value === void 0 ? void 0 : value.id);
    };
    return (<FormControl>
			<label htmlFor="programId" className="font-medium text-sm my-1">
				<RequiredMark />
				Program
			</label>
			<ProgramAutocompleteInput id="programId" onChange={onChange} value={field.value}/>
			<ErrorField error={(_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message}/>
		</FormControl>);
};
const AddCreatorsToProgramFormProvider = ({ children }) => {
    const { form, onSubmit } = useAddCreatorsToProgramForm();
    return (<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
		</FormProvider>);
};
const useAddCreatorsToProgramForm = () => {
    const modal = useModal();
    const form = useForm({
        resolver: zodResolver(addCreatorsToProgramSchema),
    });
    const addCreatorsToProgramMutation = trpc.ambassador.addCreatorsToProgram.useMutation();
    const trpcUtils = trpc.useUtils();
    const creators = useCreators();
    const onSubmit = (formData) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { addedCreatorsCount, duplicatedCreators } = yield addCreatorsToProgramMutation.mutateAsync({
                programId: formData.programId,
                creators,
            });
            if (addedCreatorsCount) {
                toast.success(`${addedCreatorsCount} were added to program`);
            }
            if (duplicatedCreators.length) {
                toast.error(<DuplicatedCreatorsMessage creators={duplicatedCreators}/>);
            }
            void trpcUtils.ambassador.getCreatorsList.invalidate();
            modal.hide();
        }
        catch (err) {
            const message = getErrorMessage(err);
            toast.error(message);
        }
    });
    return { form, onSubmit };
};
const DuplicatedCreatorsMessage = ({ creators }) => {
    return (<div>
			<p className="my-2 font-medium">Next creators already added to program({creators.length}):</p>
			<ul className="list-disc ml-3">
				{creators.map((creator) => {
            return <li>{creator.displayName}</li>;
        })}
			</ul>
		</div>);
};
const useCreators = () => {
    const { selectedCreators, creatorsIdentities } = useActionModalContext();
    const creators = selectedCreators.map(creator => ({
        userId: creator.userId,
        isOutsideCreator: creator.isOutsideCreator,
    }));
    return [...creators, ...creatorsIdentities];
};
const HelperText = () => {
    const creators = useCreators();
    return (<>
			<div className="w-full h-[2px] bg-dark-2"/>
			<p className="my-2">
				The <span className="font-medium">{creators.length}</span> {formalOrdinal(creators.length)} will be added to program
			</p>
		</>);
};
const enOrdinalRules = new Intl.PluralRules('en-US');
const suffixes = new Map([
    ['one', 'creator'],
    ['other', 'creators'],
]);
const formalOrdinal = (n) => {
    return suffixes.get(enOrdinalRules.select(n));
};
