import { useMemo, createContext, useContext } from 'react';
import { useModal } from 'root/src/client/v2/common/contexts/modal.context';
import { useSelectCreators } from 'root/src/client/v2/_pages/ambassador-program/context/ambassadorTableActions.context';
export const useActionModal = () => {
    const modal = useModal();
    const provider = useActionModalProvider();
    return {
        show: (newModal) => {
            return modal.show(<ActionModalContextProvider provider={provider}>{newModal}</ActionModalContextProvider>);
        },
        hide: modal.hide,
    };
};
const ActionModalContext = createContext(null);
export const ActionModalContextProvider = ({ children, provider }) => {
    const value = useMemo(() => {
        return {
            selectedCreators: provider.selectedCreators || [],
            creatorsIdentities: provider.creatorsIdentities || [],
        };
    }, [provider]);
    return (<ActionModalContext.Provider value={value}>
			{children}
		</ActionModalContext.Provider>);
};
const useActionModalProvider = () => {
    const { selectedCreators } = useSelectCreators();
    return { selectedCreators };
};
export const useActionModalContext = () => {
    const context = useContext(ActionModalContext);
    if (!context) {
        throw new Error('useActionModalContext should be use within ActionModalContextProvider');
    }
    return context;
};
